<template>
    <div>
        <div v-if="!$apollo.loading && page" :class="`--${pageClassName}`" class="page">
            <component v-if="page" :is="component" :page="page" />
            <NotFound v-else />
            <ContentGuard v-if="page.access" :access="page.access" />
        </div>
        <NotFound v-if="!$apollo.loading && !page && !typename" />
    </div>
</template>

<script>
import gql from 'graphql-tag';
import NotFound from '@page/system/NotFound';
import Informational from '@page/core/Informational';
import CompleteInformational from '@fragment/pages/CompleteInformational';
import { pageMap } from '@apollo/pageMap';
import debug from '@mixin/debug';
import ContentGuard from '@global/ContentGuard';
import { mapActions } from 'vuex';

export default {
    name: 'Page',
    mixins: [debug],
    data() {
        return {
            pageFragment: '',
        };
    },
    apollo: {
        page: {
            query() {
                const fragment = pageMap[this.pageFragment]?.fragment || CompleteInformational;
                const queryFragment = gql`
                    query Page($urlPath: String!, $token: String) {
                        page(urlPath: $urlPath,token: $token) {
                            __typename
                            id
                            seoTitle
                            searchDescription
                            ...${this.pageFragment}
                        }
                    }
                    ${fragment}
                `;
                if (process.env.NODE_ENV === 'development') {
                    // setTimeout interrupts vue reactivity triggering unwanted queries with apollo when the route changes.
                    setTimeout(() => {
                        this.writeLastQuery(
                            queryFragment,
                            {
                                urlPath: this.$route.path,
                                token: this.$route.query.token,
                            },
                            this.pageFragment,
                        );
                    }, 0);
                }

                return queryFragment;
            },
            prefetch: true,
            fetchPolicy: 'cache-and-network',
            skip() {
                return !this.pageFragment;
            },
            variables() {
                return {
                    urlPath: this.$route.path,
                    token: this.$route.query.token,
                };
            },
        },
        typename: {
            query() {
                return gql`
                    query Typename($urlPath: String!) {
                        typename(urlPath: $urlPath)
                    }
                `;
            },
            prefetch: true,
            fetchPolicy: 'cache-and-network',
            variables() {
                return {
                    urlPath: this.$route.path,
                };
            },
            result({ data }) {
                if (data && data.typename) {
                    this.pageFragment = 'Complete' + data.typename;
                    this.$apollo.queries.page.refetch();
                }
            },
        },
    },
    computed: {
        pageClassName() {
            return this.page ? this.page.__typename : this.$route.path.replaceAll('/', '-');
        },
        component() {
            return pageMap[this.pageFragment]?.component || Informational;
        },
    },
    methods: {
        ...mapActions('siteNavigation', ['setNavigationVisibility']),
        ...mapActions('user', ['ensureToUCompliance']),
        setNavigationDisplay() {
            if (this.page?.__typename === 'MicrositePage' || this.page?.__typename === 'MicrositeInformational') {
                this.setNavigationVisibility(false);
            } else {
                this.setNavigationVisibility(true);
            }
        },
    },
    head() {
        // adjust the <head> information for each page in here.
        // make sure the page object is available before getting values.
        // https://nuxtjs.org/docs/features/meta-tags-seo#local-settings
        let title = 'EL Education';
        if (this.page?.seoTitle || this.page?.title) {
            const pageTitle = this.page.seoTitle || this.page.title;
            title += ` - ${pageTitle}`;
        }

        const metaData = [
            {
                hid: 'description',
                name: 'description',
                content: this.page?.searchDescription || '',
            },
        ];

        const noIndexMeta = { hid: 'robots', name: 'robots', content: 'noindex' };

        const showNoIndex = this.page?.noIndex;

        return {
            title,
            meta: showNoIndex ? metaData.concat(noIndexMeta) : metaData,
        };
    },
    mounted() {
        this.$nextTick(() => {
            // allows for queuing calls to the API before Hotjar has loaded
            // https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#best_practices
            window.hj =
                window.hj ||
                function () {
                    // eslint-disable-next-line no-undef
                    (hj.q = hj.q || []).push(arguments);
                };
            // hotjar analytics call
            window.hj('identify', null, {
                access: this.user.access,
                isUserAuthed: this.isUserAuthed,
            });
        });
    },
    beforeUpdate() {
        this.setNavigationDisplay();
        this.ensureToUCompliance();
    },
    components: {
        NotFound,
        ContentGuard,
    },
};
</script>
